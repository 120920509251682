<template>
  <div>
    <div class="footer">
      <div class="footer-top">
        <img class="footer-img" src="../assets/img/logo-white.png" alt="" />
        <p>
          广州禧闻信息技术有限公司位于广州市海珠区，是一家通过运用移动互联网、物联网和大数据技术进行创新融合，基于BIM技术在国内建筑行业、智慧医疗、智慧园区、指挥调度平台中的信息化应用致力于对信息管理系统开发、控制和人工智能系统研究，安全工程智能化监测的智能化系统集成高科技企业。
        </p>

        <div class="fote" >
          <div class="top">
            <img src="../assets/img/dizhi.png" alt="" />
            <span >广州市海珠区琶洲会展产业园A栋603-604</span>
          </div>
          <div class="top">
            <img src="../assets/img/phone.png" alt="" />
            <span>电话：020-31125068</span>
          </div>
          <div class="top ">
            <img src="../assets/img/emial.png" alt="" />
            <span>邮箱：marketing@xiwen.ink</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_yi">
      <p class="mrl">
        版权信息：Copyright © 2021-2022 广州禧闻信息技术有限公司 版权所有 &nbsp;
        &nbsp; | &nbsp; &nbsp; 
        <a href="https://beian.miit.gov.cn/" target="_blank" class="aBox">粤ICP备2021154795号-1 </a>
        &nbsp;&nbsp;
        <img
          style="
            width: 15px;
            height: 15px;
            vertical-align: middle;
            margin-bottom: 3px;
          "
          src="../assets/img/icon.png"
          alt=""
        />
        <a href="http://www.beian.gov.cn/" target="_blank" class="aBox">粤公网安备 44010502002146号</a>
      </p>
    </div>
    <div class="footer_yi1">
      <p class="mrl">
        <a href="https://beian.miit.gov.cn/" target="_blank" class="aBox">粤ICP备2021154795号-1 </a>

        <img
          src="../assets/img/icon.png"
          alt=""
        />
        <a href="https://beian.miit.gov.cn/" target="_blank" class="aBox">粤ICP备2021154795号-1 </a>
      </p>
      <p class="p">版权信息：Copyright © 2021-2022 广州禧闻信息技术有限公司 版权所有</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang='less'>
.footer {
  height: 220px;
  margin: 0 auto;
  background-color: #1c3263;
  color: #fff;
  text-align: center;
  padding: 30px 0;
  font-size: 14px;
  box-sizing: border-box;

  .footer-top {
    margin: 0 auto;
    width: 1000px;
    img {
      width: 120px;
    }
    p {
      margin-top: 20px;
    }
    .fote {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .top {
        display: inline-block;
        vertical-align: middle;
        
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          vertical-align: middle;
          margin-bottom: 4px;
        }
        span {
          display: inline-block;
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }
}
.footer_yi {
  background-color: #1c3263;
  text-align: center;
  .mrl {
    border-top: 1px solid #f8f8f8;
    padding: 20px 0;
    margin: 0;
    font-size: 12px;
    color: #f8f8f8;
    img{
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-bottom: 3px;
      margin-right: 5px;
    }
    .aBox{
      color: #fff;
      text-decoration-line: none
    }
    .aBox:hover{
      text-decoration-line: underline
    }
  }
}
.footer_yi1{
  display: none;
}

@media (max-width: 750px) {
  .footer {
    padding: 1px 15px;
    height: 310px;
    .footer-top {
      width: 100%;
      text-align: left;
      .footer-img {
        display: none;
      }
      p {
        line-height: 25px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      }
      .fote {
        display: block;
        margin-top: 10px;
        word-wrap:break-word;
       
        .top {
          line-height: 33px;
          display: inline-block;
          word-wrap:break-word;
          span{
            display: inline;
          }
        }
      }
    }
  }
  .footer_yi {
    display: none;
  }
  .footer_yi1 {
    background-color: #1c3263;
    text-align: center;
    color: #f8f8f8;
    height:100px;
    display: block;

    .mrl {
      border-top: 1px solid #f8f8f8;
      padding: 10px 14px;
      padding-top: 22px;
      img{
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
    .aBox{
      color: #fff;
      text-decoration-line: none
    }
    .aBox:hover{
      text-decoration-line: underline
    }
    p {
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #f8f8f8;
      text-align: left;
      font-size: 12px;
    }
    .p{
      padding: 0 14px;
    }

  }
}
</style>