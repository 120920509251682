<template>
    <div class="solutionBox ">
      <div class="top_left">
        <!-- <img src="../assets/img/jiejuefangan.jpg"> -->
      </div>
      <div class="bottombox" >
        <!-- <div class="titlebox">
            <div class="spanbox"><span class="span1">首页</span ><span class='span2'>></span><span class="span3">产品中心</span></div>
        </div> -->
        <div class="titlebox1">
            <el-divider content-position="center">解决方案</el-divider>
        </div>
        <div class="productlistbox"> 
          <div class="imgListbox">
            <div class="itembox  bg248  dflex fcolumn alcenter boxhover br5" @click="godetail(1)">
                <img class="ofcover mrt5" src="../assets/img/jiedao.jpg">
                <p >街道社会综合治理联动管理平台</p>
            </div>
            <div class="bg248 itembox dflex fcolumn alcenter boxhover br5" @click="godetail(2)">
                <img class="ofcover mrt5" src="../assets/img/gaoxinqu.jpg">
                <p class="">高新区管委会招商企业服务智慧平台</p>
            </div>
            <div class="bg248 itembox dflex fcolumn alcenter boxhover br5" @click="godetail(3)">
                <img class="ofcover mrt5" src="../assets/img/yangjiang.jpg">
                <p class="">危化品智慧管控平台解决方案</p>
            </div>
            <div class="bg248 itembox dflex fcolumn alcenter boxhover br5" @click="godetail(4)">
                <img class="ofcover mrt5" src="../assets/img/diancan.jpg">
                <p class="">智慧校园零接触点餐平台</p>
            </div>
            <div class="bg248 itembox dflex fcolumn alcenter boxhover br5" @click="godetail(5)">
                <img class="ofcover mrt5" src="../assets/img/yuanqu.jpg">
                <p class="">智慧园区生态运营平台</p>
            </div>
            <div class="bg248 itembox dflex fcolumn alcenter boxhover br5" @click="godetail(6)">
                <img class="ofcover mrt5" src="../assets/img/yiyuan.jpg">
                <p class="">智慧医院后勤运维管理平台解决方案</p>
            </div>
          </div>
          <!-- <div class="imgListbox">
            <div class="bg248 w290 h230 dflex fcolumn alcenter boxhover br5" @click="godetail(4)">
                <img class="ofcover mrt5" src="../assets/img/diancan.jpg">
                <p class="">智慧校园零接触点餐平台</p>
            </div>
            <div class="bg248 w290 h230 dflex fcolumn alcenter boxhover br5" @click="godetail(5)">
                <img class="ofcover mrt5" src="../assets/img/yuanqu.jpg">
                <p class="">智慧园区生态运营平台</p>
            </div>
            <div class="bg248 w290 h230 dflex fcolumn alcenter boxhover br5" @click="godetail(6)">
                <img class="ofcover mrt5" src="../assets/img/yiyuan.jpg">
                <p class="">智慧医院后勤运维管理平台解决方案</p>
            </div>
            <div class="bg248 w290 h230 dflex fcolumn alcenter boxhover br5">
                <img class="ofcover mrt5" src="../assets/img/diancan.jpg">
                <p class="f14">禧闻云智慧校园零接触点餐平台</p>
            </div>
          </div> -->
        </div>
        
      </div>
      <footers></footers>
    </div>
  </template>
  
  <script>
  import footers from "../components/footers.vue";
  export default {
    components: {
      footers
    },
   
    methods:{
      godetail(val){
        this.$router.push(`/solutionDetail?keyword=${val}`)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>

    .top_left{
      width: 100%;
      height: 300px;
      background: url("../assets/img/jiejuefangan.jpg") no-repeat left center;
      background-size: 100% 100%;
    }
    .titlebox1{
      width: 1200px;
      margin-top: 44px;
      font-size: 24px;
    }
    .productlistbox{
      width: 1258px;
      margin-bottom: 60px;
      
    }
    .bottombox{
      display: flex;
      align-items: center;
      flex-direction: column;
        .titlebox{
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          height: 60px;
          width: 1000px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span{
            font-size: 12px;
          }
          .span1{
            color: #073763;
          }
          .span2{
            margin: 0 10px;
          }
          .span3{
            color: #000;
          }
        }
    }
    
  
  .imgListbox{
    display: flex;
    align-items: center;
    // margin-top: 43px;
    flex-flow:row wrap;
    justify-content: space-around;
    .itembox{
      width: 290px;
      height: 230px;
      margin-top: 45px;
      p{
        font-size: 14px;
      }
    }
  }
    .boxhover{
        cursor: pointer;
        margin: 0 42.5px;
        background-color: rgba(248, 248, 248, 1);
        p{
            color: rgba(7, 55, 99, 1);
        }
    }
    .boxhover:hover{
        background-color: #0b5394;
        p{
            color: #FFFFFF;
        }
    }

    @media (max-width: 750px) {
      .top_left {
        width: 100%;
        height: 150px;
        background: url("../assets/img/jiejuefangan.png") no-repeat left center;
        background-size: 100% 100%;
      }
      .titlebox1{
        margin-top: 20px;
        font-size: 18px;
        .el-divider--horizontal{
          margin: 12px 0 2px 0;
        }
      }
      .productlistbox{
      width: 100%;
      margin-bottom: 20px;
      .imgListbox{
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow:row wrap;
        .itembox{
          width: 170px;
          height: 145px;
          img{
            width: 151px;
            height: 97px;
          }
          p{
            font-size: 10px;
          }
          
        }
        .boxhover{
          margin: 22px  0 0 0;
        }
      }
    }
    }
  </style>
  
  <style lang="less">
  .solutionBox{
    .el-divider__text{
      color: #3D3D3D;
      font-size: inherit;
      font-weight: bold;
    }
  }
  
  </style>